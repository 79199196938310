import { Zoom } from "react-awesome-reveal";
import { Accordion, Table } from "react-bootstrap";
import React from "react";
import { Link, NavLink } from "react-router-dom";

var questions = [
  "¿Cómo puedo registrarme para realizar apuestas deportivas?",
  "¿Dónde puedo mirar estadísticas y pronósticos en la casa de apuestas Codere?",
  "¿Cuáles son los tipos de apuestas deportivas en fútbol?",
  "¿Qué es una Freebet y cómo lo puedo utilizar?",
  "¿Qué es una apuesta combinada?",
];
var answers = [
  "Para registrarte en Codere y poder realizar apuestas deportivas primero tenés que crearte una cuenta. Hacé clic en la pestaña “Registrarse” y completá tus datos personales para crear tu perfil. Tené a mano tu DNI ya que te solicitaremos el número de trámite. Recordá que solo podrás apostar cuando te encuentres ubicado dentro de la Ciudad Autónoma de Buenos Aires. Aprovechá nuestro bono de bienvenida y usalo en apuestas deportivas. Luego de crear tu cuenta no olvides realizar la verificación documental.",
  "Antes de realizar tus apuestas deportivas en Codere, tenés la opción de ayudarte con nuestras estadísticas o pronósticos. Hacé clic en el evento deportivo sobre el que quieras apostar y entrá a uno de los partidos. Una vez dentro, verás que se abrirá un panel lateral con todo tipo de ayudas. Contarás con información sobre ambos equipos, el estadio donde se juega, horas que quedan para el inicio, árbitro, goles marcados esta temporada, máximos goleadores, etc. Además, habrá otras pestañas en las que podrás ver los enfrentamientos anteriores entre ambos, la probabilidad de victoria de cada uno, la ubicación en la tabla de posiciones y las alineaciones probables en el caso del fútbol.",
  "Existen infinidad de mercados para realizar apuestas deportivas. Pero por encima del resto hay unos cuantos que son los más elegidos por parte de los usuarios de Codere en otros países de América y Europa. Entre ellos destacan: Ganador del partido, Más o menos goles, Primer equipo en hacer un gol, Goleadores, Hándicap e incluso apuestas anticipadas para apostar al ganador de una competición.",
  "Una Freebet es una apuesta gratis que Codere te brinda. No ponés en riesgo tu dinero y podés obtener ganancias si ganás la apuesta. Existen freebets de diferentes montos que son entregadas dependiendo de la promoción vigente. En Codere, las Freebet de bono de bienvenida son otorgadas cuando un usuario se registra y hace su primer depósito. Todas las freebets pueden ser usadas en cualquier tipo de apuesta deportiva, siempre y cuando cumpla con los términos y condiciones. Al hacer tu apuesta, al lado del botón de hacer apuesta, estará un cuadro que dirá Freebet. Al hacer clic ahí, se muestra la cantidad de Freebet que tenés disponible y podrás realizar tu apuesta con Freebet.",
  "En Codere, al hacer una apuesta deportiva con varias selecciones de eventos o incidencias dentro del mismo evento, se le llama apuesta combinada. Cuantos más eventos incluyas en la apuesta, mayor es la ganancia posible que va aumentando exponencialmente. Para ganar una apuesta combinada de todos los juegos seleccionados debés acertar la totalidad de tus predicciones dentro de la apuesta. Atentos que en Codere a veces aumentamos la cantidad de ganancia por las líneas dentro de una apuesta combinada, es decir damos un porcentaje extra por selecciones adicionales.",
];

function Content() {
  return (
    <div className="content-container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-4 left-col">
          <Zoom triggerOnce>
            <h1>La Casa Líder de Apuestas Deportivas en Argentina</h1>
            <p>
              Codere es tu mejor opción para apuestas deportivas online en
              Argentina. Como una de las casas de apuestas más confiables,
              ofrecemos cuotas competitivas y mercados únicos en deportes como
              fútbol, tenis, rugby, y básquet. Nuestra plataforma está diseñada
              para garantizar una experiencia de juego segura y emocionante.
            </p>

            <h2>Seguridad y Confianza en Apuestas en Línea </h2>
            <p>
              En Codere, priorizamos la seguridad de nuestros usuarios. Con
              licencias oficiales y métodos de pago seguros, podés disfrutar de
              tus apuestas en línea con total tranquilidad. Además, nuestras
              opciones de apuestas en vivo te permiten participar en el juego
              mientras sucede, llevando la emoción a otro nivel.
            </p>
            <h2>Variedad de Opciones en Apuestas Deportivas </h2>
            <p>
              Codere te ofrece una amplia selección de deportes y competiciones.
              Desde la Liga Profesional de Fútbol hasta la Champions League,
              pasando por disciplinas como el tenis, rugby y el básquet. Aquí
              encontrarás mercados emocionantes que se adaptan a todo tipo de
              apostadores, incluyendo:
            </p>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4 middle-col">
          <Zoom triggerOnce>
            <ul>
              <li>
                <u>Apuesta 2X1:</u> Ideal para quienes buscan reducir riesgos.
                Por ejemplo, en un partido entre River Plate y Boca Juniors,
                podés apostar a que River gana o empata. Si se da cualquiera de
                las dos opciones, ¡ganás!
              </li>
              <li>
                <u>Doble oportunidad:</u> Duplicá tus posibilidades de ganar
                apostando a dos resultados posibles. Por ejemplo, apostar a que
                Racing Club gana o empata frente a Independiente.
              </li>
              <li>
                <u>Marcador exacto:</u> Predecí el resultado final exacto del
                partido. Por ejemplo, apostar a que el partido entre San Lorenzo
                y Rosario Central termina 2-1.
              </li>
              <li>
                <u>Hándicap:</u> Perfecto para partidos desiguales. Por ejemplo,
                en un partido entre Talleres y Arsenal, podés apostar con un
                hándicap de -1 para Talleres, lo que implica que deben ganar por
                más de un gol de diferencia.
              </li>
              <li>
                <u>Primero en marcar:</u> Apostá al primer equipo en hacer un
                gol. Por ejemplo, en un encuentro entre Vélez y Huracán, podés
                apostar a que Vélez será el primero en marcar.
              </li>
              <li>
                <u>Apuestas combinadas:</u> Combiná varias selecciones en una
                sola apuesta para aumentar las ganancias. Por ejemplo, apostar a
                que Boca Juniors gana, River empata y Racing Club marca más de
                2.5 goles.
              </li>
              <li>
                <u>Ambos equipos marcan:</u> Apostá a que ambos equipos anotarán
                durante el partido. Por ejemplo, en un clásico entre
                Independiente y Racing Club, apostás que ambos equipos marcarán
                al menos un gol.
              </li>
            </ul>
            <p>
              Estos tipos de apuestas te ofrecen opciones estratégicas y
              entretenidas, maximizando la emoción de cada partido. ¿Listo para
              apostar con Codere y disfrutar del mejor entretenimiento deportivo
              en Argentina?
            </p>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4 right-col">
          <Zoom triggerOnce>
            <aside>
              <h2>Más que Apuestas: Viví el Casino Online de Codere</h2>
              <p>
                En Codere no solo somos referentes en apuestas deportivas,
                también te ofrecemos un casino online completo, respaldado por
                tecnología de última generación para garantizarte una
                experiencia de juego justa, emocionante y segura.
              </p>
              <h3>¿Qué Encontrás al Jugar Online? </h3>
              <p>
                Sumergite en una experiencia única diseñada para mantenerte
                entretenido y lleno de adrenalina:
              </p>
              <ul>
                <li>
                  <Link className="casino-section" to={`/casino/ruleta`}>
                    Ruleta online
                  </Link>
                  : Sentí la emoción de este clásico juego de casino, disponible
                  para vos en cualquier momento y lugar.
                </li>
                <li>
                  <Link className="casino-section" to={`/casino/slots`}>
                    Tragamonedas clásicas
                  </Link>
                  : Probá los slots más populares y descubrí los premios
                  increíbles que te esperan.
                </li>
                <li>
                  <Link className="casino-section" to={`/casino/blackjack`}>
                    Mesas en vivo de blackjack
                  </Link>
                  : Jugá con crupieres reales en tiempo real y viví la
                  experiencia de un casino físico sin salir de casa.
                </li>
                <li>
                  <Link
                    className="casino-section"
                    to={`/casino/casino-en-vivo`}
                  >
                    Casino en vivo
                  </Link>
                  : Disfrutá de una experiencia inmersiva con tecnología de
                  punta que te transporta al corazón de la acción.
                </li>
                <li>
                  <Link className="casino-section" to={`/casino`}>
                    Casino online
                  </Link>
                  : Todo el realismo y la emoción de un casino físico, ahora en
                  la palma de tu mano.
                </li>
              </ul>
            </aside>
            <h2>Aprovecha tu Bono de Bienvenida en Deporte</h2>
            <p>
              ¡Empezá a apostar con una ventaja exclusiva! En Codere te damos la
              bienvenida con un bono de hasta $200.000 en freebets para nuevos
              usuarios. Aprovechá esta increíble oportunidad para explorar
              nuestra plataforma y descubrir por qué somos líderes en apuestas
              online en Argentina.
            </p>
            <p>¡Registrate ahora y empezá a ganar desde el primer momento!</p>
          </Zoom>
        </div>
      </div>
      <Table className="mt-3 mb-3" striped bordered hover variant="dark">
        <tbody>
          <tr>
            <td>⚽ Apuestas Deportivas</td>
            <td>Apuestas fútbol, Apuestas box, Apuestas baloncesto y más</td>
          </tr>
          <tr>
            <td>🎲 Licencia</td>
            <td>
              LOTBA S.E. mediante DI-2021-238-GCABA-LOTBA y
              DI-2023-359-GCABA-LOTBA.
            </td>
          </tr>
          <tr>
            <td>🎲 Licencia</td>
            <td>
              Licencia en la Provincia de Mendoza mediante Resolución N°
              192/2023
            </td>
          </tr>
          <tr>
            <td>✔️ Moneda</td>
            <td>ARS</td>
          </tr>
        </tbody>
      </Table>

      <section id="acc-section" className="mt-4">
        <h2 style={{ color: "#79c000" }}>
          Preguntas frecuentes sobre Apuestas deportivas en Argentina
        </h2>
        <Accordion
          className="content-accordion"
          defaultActiveKey={["0"]}
          alwaysOpen
        >
          {answers.map((answer, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header as="h3">
                  <p>{questions[index]}</p>
                </Accordion.Header>
                <Accordion.Body>
                  <p>{answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </section>
    </div>
  );
}

export default Content;
